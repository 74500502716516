/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons/lib/icons';

import { GARAGE_MANAGEMENT_ENTITY } from '../../constants/EntityName';

import callApi from '../../Api/config';
import ImageCard from '../GarageDetailCards/ImageCard';
import FieldCard from '../GarageDetailCards/FieldCard';
import Staff from '../Staff';
import GarageModal from '../GarageModal';
import BankDetails from '../BankDetails';
import { GarageStatus, NextFrom } from '../../constants/AppConstant';
import GarageMarketing from '../GarageMarketing';
import GarageServices from '../GarageServices';
import { toastSuccess } from '../../service/Toast';

import './style.scss';

const GarageDetail = () => {
    const [currentTab, setCurrentTab] = useState<string>('Basic Details');

    const [isModal, setIsModal] = useState<boolean>(false);

    const [getApplicationData, setGetApplicationData] = useState<any>({});

    const [btnClicked, setBtnClicked] = useState<any>({ accept: true, reject: true });

    const location = useLocation();

    const garageId = location.search.slice(1);

    const history = useHistory();

    const handleNavigateToGarage = () => {
        history.push(GARAGE_MANAGEMENT_ENTITY);
    };

    const tabsConfig: any = {
        'Basic Details': NextFrom.BASIC_DETAIL,
        'Bank Details': NextFrom.BANK_DETAIL,
        'Marketing': NextFrom.MARKETING,
        'Services': NextFrom.SERVICE,
        'Staff': NextFrom.STAFF,
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await callApi(
                    `${GARAGE_MANAGEMENT_ENTITY}/application/${garageId}/${tabsConfig[currentTab]}`,
                    'GET',
                );
                setGetApplicationData(res?.data?.data?.mainGarageDetails);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab]);

    const addComment = () => {
        setIsModal(true);
    };

    const postComment = async (data: any) => {
        try {
            const res = await callApi(`${GARAGE_MANAGEMENT_ENTITY}/review/${garageId}`, 'POST', data);
            setIsModal(false);
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };

    const handleAcceptBtnClick = async () => {
        try {
            const res = await callApi(`${GARAGE_MANAGEMENT_ENTITY}/${garageId}/${GarageStatus.ACCEPTED}`, 'PATCH');
            setBtnClicked((prev: any) => ({ ...prev, accept: false }));

            if (res) {
                toastSuccess('Garage Approved');
                handleNavigateToGarage();
            }
        } catch (error) {
            console.error('Error accepting garage:', error);
        }
    };

    const handleRejectBtnClick = async () => {
        try {
            const res = await callApi(`${GARAGE_MANAGEMENT_ENTITY}/${garageId}/${GarageStatus.REJECTED}`, 'PATCH');
            setBtnClicked((prev: any) => ({ ...prev, reject: false }));
            if (res) {
                toastSuccess('Garage Rejected');
                handleNavigateToGarage();
            }
        } catch (error) {
            console.error('Error rejecting garage:', error);
        }
    };

    const openModal = () => {
        setIsModal(true);
    };

    const closeModal = () => {
        setIsModal(false);
    };

    const keyData = [
        'garageName',
        'garageEmail',
        'phoneNumber1',
        'phoneNumber2',
        'operationalHours',
        'dateOfEstablishment',
        'addressLine1',
        'addressLine2',
        'city',
        'zipCode',
        'mapLocation',
        'branchName',
        'buildingNumber',
        'contactPersonName',
        'status',
        'dateOfEstablishment',
        'pickUpSlotLimit',
        'contactPersonNumber',
        'contactPersonEmail',
    ];

    function formatString(inputString: string) {
        let formattedString = inputString.charAt(0).toUpperCase() + inputString.slice(1);

        formattedString = formattedString.replace(/([A-Z])/g, ' $1');

        return formattedString;
    }

    const currentComponent = () => {
        switch (currentTab) {
            case 'Basic Details':
                return (
                    <>
                        {
                            <div>
                                <div className='branch'>{getApplicationData.branchName}</div>
                                <div className='imageCards'>
                                    {Object.entries(getApplicationData).map(
                                        ([key, value]) =>
                                            ['logo', 'commercialRegistration', 'tradeLicense', 'computerCard'].includes(
                                                key,
                                            ) && (
                                                <ImageCard
                                                    key={key}
                                                    addComment={addComment}
                                                    title={formatString(key)}
                                                    image={value as string}
                                                />
                                            ),
                                    )}
                                </div>

                                <div className='fieldCards'>
                                    {Object.entries(getApplicationData)
                                        .filter(([key, value]) => keyData.includes(key))
                                        .map(([key, value]) => (
                                            <>
                                                <FieldCard
                                                    key={key}
                                                    addComment={addComment}
                                                    placeholder={value as string}
                                                    title={formatString(key)}
                                                    copyValue={value}
                                                />
                                            </>
                                        ))}
                                </div>
                            </div>
                        }
                    </>
                );

            case 'Staff':
                return <Staff />;

            case 'Bank Details':
                return <BankDetails />;

            case 'Marketing':
                return <GarageMarketing />;

            case 'Services':
                return <GarageServices />;
        }
    };

    return (
        <div>
            <div className='titleButtonContainer'>
                <div className='garageTitle'>
                    <h2>Garage Details</h2>
                </div>
                <div className='rejectAcceptBtn'>
                    <div className='backToGarageList' onClick={handleNavigateToGarage}>
                        <ArrowLeftOutlined />
                        back to Garage List
                    </div>
                    <div className='garageApproveBtn'>
                        <Button danger disabled={!btnClicked.accept} onClick={handleRejectBtnClick}>
                            Reject
                        </Button>
                        <Button id='acceptBtn' danger onClick={handleAcceptBtnClick} disabled={!btnClicked.reject}>
                            Accept
                        </Button>
                    </div>
                </div>
            </div>
            <div className='tabLinks'>
                <div className='links'>
                    {Object.entries(tabsConfig).map(([tabName, tabConfig]) => (
                        <p
                            key={tabName}
                            id={tabName.replace(/\s+/g, '')}
                            className={currentTab === tabName ? 'active' : ''}
                            onClick={() => setCurrentTab(tabName)}>
                            {tabName}
                        </p>
                    ))}
                </div>
            </div>

            {currentComponent()}
            {isModal && (
                <GarageModal
                    currentTab={currentTab}
                    postComment={postComment}
                    isOpen={isModal}
                    onclose={closeModal}></GarageModal>
            )}
        </div>
    );
};

export default GarageDetail;
